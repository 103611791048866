import React from 'react';
import Footer from '../components/footer';

const Terms = () => {
    return (
        <div>
            <div className="min-h-screen bg-gray-100 py-12 mx-auto text-left px-4 sm:px-6 lg:px-8 pt-24 pb-20">
                <div className="max-w-3xl mx-auto">
                    <h1 className="text-3xl font-bold mb-4">Terms of Service</h1>

                    <p className="text-sm text-gray-600 mb-8">
                        **Last Updated:** August 28, 2024
                    </p>

                    {/* Introduction */}
                    <h2 className="text-xl font-bold my-4">1. Introduction</h2>
                    <p>
                        Welcome to ReviewMate.AI, a service provided by Learning Outcomes Academy ("Company", "we", "our", "us"). By accessing or using our website located at reviewmate.ai (the "Site"), you agree to be bound by these Terms of Service ("Terms"). If you do not agree to these Terms, please do not use our Site.
                    </p>

                    {/* Eligibility */}
                    <h2 className="text-xl font-bold my-4">2. Eligibility</h2>
                    <p>
                        You must be at least 18 years old to use our Site. By using our Site, you represent and warrant that you meet this age requirement.
                    </p>

                    {/* Account Registration */}
                    <h2 className="text-xl font-bold my-4">3. Account Registration</h2>
                    <p>
                        We currently offer the ability to register and log in using third-party services, such as Google. If you choose to use a third-party service to register or log in, you authorize us to access and use certain information from that service to create your account. You are solely responsible for complying with the terms and conditions of the third-party service.
                    </p>

                    {/* Services and Fees */}
                    <h2 className="text-xl font-bold my-4">4. Services and Fees</h2>
                    <p>
                        Our Site offers peer review assistant services for research papers. Each review costs $1.99 USD. Users can purchase as many credits as they like. As long as there is credit in your account, there is no limit on the number of reviews you can use the service for.
                    </p>

                    {/* Payment */}
                    <h2 className="text-xl font-bold my-4">5. Payment</h2>
                    <p>
                        All payments are processed through our third-party payment processor. By purchasing our services, you agree to pay all applicable fees and charges in accordance with the billing terms in effect at the time of purchase. All prices are exclusive of applicable taxes, which will be added at checkout.
                    </p>

                    {/* No Refunds */}
                    <h2 className="text-xl font-bold my-4">6. No Refunds</h2>
                    <p>
                        All purchases are final and non-refundable. Once a purchase is made, you will not be entitled to a refund for any reason.
                    </p>

                    {/* User Conduct */}
                    <h2 className="text-xl font-bold my-4">7. User Conduct</h2>
                    <p>
                        You agree not to use our Site for any unlawful purpose or in any way that could harm the Company or any third party. Prohibited activities include, but are not limited to:
                    </p>
                    <ul className="list-disc pl-6 mb-4">
                        <li>Impersonating any person or entity.</li>
                        <li>Interfering with the proper functioning of the Site.</li>
                        <li>Uploading or transmitting any viruses or other harmful code.</li>
                        <li>Engaging in any activity that violates any applicable law or regulation.</li>
                    </ul>

                    {/* Intellectual Property */}
                    <h2 className="text-xl font-bold my-4">8. Intellectual Property</h2>
                    <p>
                        All content on our Site, including text, graphics, logos, and software, is the property of the Company or its licensors and is protected by intellectual property laws. You agree not to reproduce, distribute, or create derivative works from any content on our Site without our prior written permission.
                    </p>

                    {/* Termination */}
                    <h2 className="text-xl font-bold my-4">9. Termination</h2>
                    <p>
                        We may terminate or suspend your account and access to our Site at any time, without prior notice or liability, for any reason, including if you breach these Terms.
                    </p>

                    {/* Disclaimer of Warranties */}
                    <h2 className="text-xl font-bold my-4">10. Disclaimer of Warranties</h2>
                    <p>
                        Our Site and services are provided "as is" without warranties of any kind, either express or implied. We do not warrant that our Site will be uninterrupted or error-free. We disclaim all warranties, including, but not limited to, the implied warranties of merchantability, fitness for a particular purpose, and non-infringement.
                    </p>

                    {/* Limitation of Liability */}
                    <h2 className="text-xl font-bold my-4">11. Limitation of Liability</h2>
                    <p>
                        To the fullest extent permitted by law, the Company shall not be liable for any indirect, incidental, special, consequential, or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill, or other intangible losses, resulting from (a) your use or inability to use our Site; (b) any unauthorized access to or use of our servers and/or any personal information stored therein; (c) any interruption or cessation of transmission to or from our Site; (d) any bugs, viruses, trojan horses, or the like that may be transmitted to or through our Site by any third party; or (e) any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through the Site.
                    </p>

                    {/* Governing Law */}
                    <h2 className="text-xl font-bold my-4">12. Governing Law</h2>
                    <p>
                        These Terms shall be governed and construed in accordance with the laws of Ontario, Canada, without regard to its conflict of law provisions.
                    </p>

                    {/* Changes to Terms */}
                    <h2 className="text-xl font-bold my-4">13. Changes to Terms</h2>
                    <p>
                        We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material, we will provide at least 30 days' notice prior to any new terms taking effect. What constitutes a material change will be determined at our sole discretion.
                    </p>

                    {/* Contact Us */}
                    <h2 className="text-xl font-bold my-4">14. Contact Us</h2>
                    <p>
                        If you have any questions about these Terms, please contact us at support@reviewmate.ai.
                    </p>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Terms;
