import React from 'react';
import Footer from '../components/footer';
import { useState, useEffect } from 'react';
import lp1 from '../assets/lp1.svg';
import lp2 from '../assets/lp2.svg';
import lp3 from '../assets/lp3.svg';
import front_img1 from '../assets/frontpage_display/front_img1.png';
import front_img2 from '../assets/frontpage_display/front_img2.png';
import front_img3 from '../assets/frontpage_display/front_img3.png';
import front_img4 from '../assets/frontpage_display/front_img4.png';
import front_img5 from '../assets/frontpage_display/front_img5.png';
import front_img6 from '../assets/frontpage_display/front_img6.png';
// import { textAlign } from 'html2canvas/dist/types/css/property-descriptors/text-align';
// import { textAlign } from 'html2canvas/dist/types/css/property-descriptors/text-align';
// import { position } from 'html2canvas/dist/types/css/property-descriptors/position';

const Home = () => {
  const images = [
    front_img1,
    front_img2,
    front_img3,
    front_img4,
    front_img5,
    front_img6,
  ];
  const img_sw_btn=[
    {action:()=>switchToImage(0)},
    {action:()=>switchToImage(1)},
    {action:()=>switchToImage(2)},
    {action:()=>switchToImage(3)},
    {action:()=>switchToImage(4)},
    {action:()=>switchToImage(5)},
    // {action:()=>switchToImage(6)},
  ];
  const [currentIndex, setCurrentIndex] = useState(0);
  // const [transformxperc, setTransformxperc] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
      // setTransformxperc(0-currentIndex*100);
    }, 5000); // Change images every 3 seconds
    return () => clearInterval(interval);
  }, [images.length]);

  const switchToImage = (x) =>{
    setCurrentIndex(x);
  };

  // Calculate the translateX value for scrolling effect
  // const translateXValue = -(currentIndex * 1420); // 310 is the image width + gap

  // Get the three images to display
  // const displayedImages = images.slice(currentIndex, currentIndex + 1);
  const transformX = 0-currentIndex*100

  // const items = Array.from({ length: 10 }, (_, index) => `Item ${index + 1}`);
  const albumContainerStyle = { 
    width: "100%",
    // height: "800px",
    overflow: "hidden",
    display: "block",
    justifyContent: "center",
    marginTop: "50px",
    paddingBottom:"30px",
  };

  const albumStyle = {
    display: "flex",
    // height: "800px",
    // width: "1000000px",
    // gap: "10px",
    // margin: "10px",
    transform: `translateX(${transformX}%)`,
    transition: "transform 0.5s ease-in-out",
  };

  const photoStyle = {
    minWidth: "100%",
    height: "100%",
    display: "inline",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f0f0f0",
    borderRadius: "10px",
    // margin: "auto",
    textAlign: "center",
    alignContent: "center",
    backgroundColor: "transparent",

    // borderStyle:"solid",
    // borderColor:"white",
    // borderWidth:"30px",

  };
  const imgStyle = {
    width: "70%",
    height: "70%",
    objectFit: "cover",
    margin: "auto",
    // textAlign: "center",
    // alignItems: "center",
    borderStyle:"solid",
    borderColor:"rgb(147 193 254)",
    borderWidth:"10px", 
  };

  const albumBtnContainer = {
    width: "100px",
    display: "flex",
    flexDirection: "row",
    marginTop:"10px",
    marginLeft: "auto",
    marginRight: "auto",
    // textAlign: "center",
  }
  //#a7a19b
  const albumBtn = {
    width: "10px",
    height: "10px",
    backgroundColor:"rgb(219 234 254)",
    cursor: "pointer",
    margin: "auto",
    borderRadius: "6px",
  }
  const albumBtnAt = {
    width: "10px",
    height: "10px",
    backgroundColor:"rgb(107 153 244)",
    cursor: "pointer",
    margin: "auto",
    borderRadius: "6px",
  }

    return (
        <div className="scroll-smooth">
        <div className="flex h-screen justify-center items-center relative max-w-screen bg-gradient-to-r from-blue-50 to-blue-100">
          <div className="mx-auto mt-10 flex flex-col justify-center px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8">
            <div className="text-center">
              <h1 className="text-4xl font-extrabold tracking-tight text-gray-900 dark:text-slate-800 sm:text-5xl md:text-6xl animate-fadeInDown">
                <span className="block xl:inline">
                  <span className="mb-1 block">Revolutionizing Research Paper Reviews with</span>
                  <span className="bg-gradient-to-r from-customLime to-customBlue bg-clip-text text-transparent">
                    Cutting-Edge AI Technology
                  </span>
                </span>
              </h1>
              <p className="mx-auto mt-3 max-w-xl text-xl text-black sm:mt-5 md:mt-5 animate-fadeInUp">
                Experience the power of AI to review research papers quickly, securely, and accurately. Get the best results in seconds.
              </p>
              <div className="mt-5 sm:mt-8 sm:flex sm:justify-center animate-fadeInUp">
                <div className="rounded-md shadow">
                  <a className="flex w-full items-center justify-center rounded-md border border-transparent bg-customBlue px-8 py-3 text-base font-medium text-white hover:bg-blue-500 md:py-4 md:px-10 md:text-lg" href="/review">
                    Start Today 🚀
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="absolute bottom-10 w-full flex justify-center animate-fadeInUp">
            <a href="#scroll-target">
              <svg className="w-8 h-8 animate-bounce" fill="none" stroke="black" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
              </svg>
            </a>
          </div>
        </div>
  
        <div className="flex flex-col items-center px-5 min-h-screen justify-center" id="scroll-target">
          <div className="text-4xl font-semibold text-black">How It Works</div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 mt-20 w-full max-w-screen-xl px-4">
                {/* Section 1 */}
                <div className="flex flex-col items-center text-center">
                <img loading="lazy" src={lp1} className="w-[150px] md:w-[180px] lg:w-[200px] aspect-square" />
                <div className="text-2xl font-semibold text-black mt-4">Transforming Academic Peer Review with AI</div>
                <div className="mt-2 text-lg leading-7 text-zinc-800">
                    ReviewMate.ai blends cutting-edge AI with meticulous diligence of reviewers, complementing human expertise to provide objective, insightful critiques.
                </div>
                </div>

                {/* Section 2 */}
                <div className="flex flex-col items-center text-center">
                <img loading="lazy" src={lp2} className="w-[150px] md:w-[180px] lg:w-[200px] aspect-square" />
                <div className="text-2xl font-semibold text-black mt-4 py-1">AI-Powered Review Assistance</div>
                <div className="mt-2 text-lg leading-7 text-zinc-800 ">
                    <br></br>
                    Our AI-driven reviewer assists at every stage, providing constructive feedback and insights to enhance your research work.
                </div>
                </div>

                {/* Section 3 */}
                <div className="flex flex-col items-center text-center">
                <img loading="lazy" src={lp3} className="w-[150px] md:w-[180px] lg:w-[200px] aspect-square" />
                <div className="text-2xl font-semibold text-black mt-4">Ensuring Document Confidentiality and Security</div>
                <div className="mt-2 text-lg leading-7 text-zinc-800">
                    Your documents and interactions are neither stored nor used for training purposes, ensuring full confidentiality and security.
                </div>
            </div>
          </div>
        </div>

        <div style={albumContainerStyle} className="text-center bg-gradient-to-r from-customBlue2 via-customBlue to-customBlue2">
          {/* bg-gradient-to-r from-blue-50 to-blue-100 */}
          <div className="text-4xl font-semibold text-blue-100 py-10">Sample review of a research paper</div>
          <div style={albumStyle}>
            {/* {displayedImages.map((src, index) => (
              <div style={photoStyle} key={index}>
                <img src={src} alt={`Image ${index + 1}`} style={imgStyle} />
              </div>
            ))} */}
            {images.map((src, index) =>(
              <div style={photoStyle} key={0}>
                <img src={src} alt={`Image ${index+1}`} style={imgStyle} />
              </div>
            ))}
              {/* <div style={photoStyle} key={0}>
                <img src={images[0]} alt={`Image ${1}`} style={imgStyle} />
              </div>
              <div style={photoStyle} key={1}>
                <img src={images[1]} alt={`Image ${2}`} style={imgStyle} />
              </div>
              <div style={photoStyle} key={2}>
                <img src={images[2]} alt={`Image ${3}`} style={imgStyle} />
              </div>
              <div style={photoStyle} key={3}>
                <img src={images[3]} alt={`Image ${4}`} style={imgStyle} />
              </div>
              <div style={photoStyle} key={4}>
                <img src={images[4]} alt={`Image ${5}`} style={imgStyle} />
              </div>
              <div style={photoStyle} key={5}>
                <img src={images[5]} alt={`Image ${6}`} style={imgStyle} />
              </div> */}
          </div>

          <div style={albumBtnContainer} className="text-center">
            {
              img_sw_btn.map((btn, index)=>(
                <div style={index==currentIndex?albumBtnAt:albumBtn} onClick={btn.action}></div>
              ))
            }
            {/* <div style={albumBtn}></div>
            <div style={albumBtn}></div>
            <div style={albumBtn}></div>
            <div style={albumBtn}></div>
            <div style={albumBtn}></div>
            <div style={albumBtn}></div> */}
            
          </div>
        </div>



        <Footer />
      </div>
    );
};

export default Home;