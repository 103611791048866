import React from 'react';
import Footer from '../components/footer';

const Privacy = () => {
    return (
        <div>
            <div className="min-h-screen bg-gray-100 py-12 mx-auto text-left px-4 sm:px-6 lg:px-8 pt-24 pb-20"> 
                <div className="max-w-3xl mx-auto">
                    <h1 className="text-3xl font-bold mb-4">Privacy Policy</h1>

                    <p className="text-sm text-gray-600 mb-8">
                        **Last updated:** July 9, 2024
                    </p>

                    {/* Introduction */}
                    <p>
                        ReviewMate.AI ("we," "us," or "our"), a service provided by Learning Outcomes Academy ("Company", "we", "our", "us"), is committed to protecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our website (ReviewMate.ai) and related services. 
                    </p>

                    {/* Scope */}
                    <h2 className="text-xl font-bold my-4">Scope</h2>
                    <p>
                        This Privacy Policy applies to information collected through your interactions with ReviewMate.ai, including website visits, account creation, and communications. It does not apply to third-party websites or services we may link to. 
                    </p>

                    {/* Information We Collect */}
                    <h2 className="text-xl font-bold my-4">Information We Collect</h2>
                    <ul className="list-disc pl-6 mb-4">
                        <li>**Account Information:** When you create an account or log in with Google, we collect your email address, contact information, and ReviewMate.ai transaction history (through Stripe).</li>
                        <li>**User Communications:** We collect your email address, ratings, and feedback messages.</li>
                        <li>**Log Data:** This includes your IP address, interactions with our website, request times, and related data.</li>
                    </ul>

                    {/* Data We Do Not Collect */}
                    <h2 className="text-xl font-bold my-4">Data We Do Not Collect</h2>
                    <p>We do not collect user content, such as files uploaded to ReviewMate.ai or messages input through the Q&A chat.</p>


                    {/* How We Use Your Information */}
                    <h2 className="text-xl font-bold my-4">How We Use Your Information</h2>
                    <p>
                        We may use your information to:
                    </p>
                    <ul className="list-disc pl-6 mb-4">
                        <li>Maintain and improve ReviewMate.ai functionality</li>
                        <li>Understand and analyze how you use our website</li>
                        <li>Communicate with you regarding updates, information, and marketing purposes</li>
                        <li>Develop new features</li>
                        <li>Prevent fraud</li>
                    </ul>

                    {/* Your Rights */}
                    <h2 className="text-xl font-bold my-4">Your Rights</h2>
                    <p>
                        You have the right to access, modify, and delete your personal information. Contact us at support@reviewmate.ai for assistance. 
                    </p>

                    {/* Data Security */}
                    <h2 className="text-xl font-bold my-4">Data Security</h2>
                    <p>
                        We use commercially reasonable measures to protect your information, but no method is completely secure. We retain your data as long as necessary for service provision and legitimate business purposes.
                    </p>

                    {/* Children's Privacy */}
                    <h2 className="text-xl font-bold my-4">Children's Privacy</h2>
                    <p>ReviewMate.ai is not intended for children under 13, and we do not knowingly collect their information.</p>

                    {/* CCPA and GDPR Rights */}
                    <h2 className="text-xl font-bold my-4">CCPA and GDPR Rights</h2>
                    <p>
                        California and EU residents have additional rights under the CCPA and GDPR. Contact us for more information.
                    </p>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Privacy;