import * as React from 'react';
import { useState } from 'react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import Footer from '../components/footer';
import logo from '../assets/logob.png';
import { PulseLoader } from 'react-spinners'; // Import the loader
import { serverUrl } from '../config';
import { useNavigate } from 'react-router-dom';

function Contact() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    message: '',
  });
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate(); // Use the useNavigate hook here

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const res = await axios.post(`${serverUrl}/feedback`, {
        email_input: formData.email,
        firstName: formData.firstName,
        lastName: formData.lastName,
        message: formData.message,
      });

      if (res.data.status === 'confirm') {
        setSubmissionStatus('Thank you for your feedback!');
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          message: '',
        });
        navigate('/contact/successful'); // Use navigate here instead of Navigate
      } else {
        setSubmissionStatus('Something went wrong. Please try again.');
      }
    } catch (error) {
      setSubmissionStatus('Error: Unable to submit feedback.');
      navigate('/contact/unsuccessful'); // Use navigate here
      console.error('Error submitting feedback:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="flex flex-col items-center pb-20 bg-white">
        <div className="flex justify-center items-center self-stretch px-16 py-20 w-full text-center bg-blue-700 text-slate-50 max-md:px-5 max-md:max-w-full">
          <div className="flex flex-col mt-5 mb-20 max-md:mb-10 max-md:max-w-full">
            <motion.div
              className="text-6xl py-8 font-extrabold tracking-tight leading-[62.4px] max-md:max-w-full max-md:text-4xl"
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              Contact us
            </motion.div>
            <motion.div
              className="self-center mt-7 text-2xl font-small leading-8 max-md:max-w-full"
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              Contact us for any question you may have
            </motion.div>
          </div>
        </div>

        {/* Form */}
        <motion.div
          className="p-10 bg-white border-2 border-customLime max-md:px-5 flex justify-center py-12 mt-[-50px] rounded-lg shadow-lg w-11/12 lg:w-3/4"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className="w-full max-w-4xl">
            <form onSubmit={handleSubmit}>
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-16">
                <div className="flex flex-col max-md:ml-0">
                  <div className="flex flex-col gap-5">
                    <div className="flex flex-col">
                      <label className="text-black">First name</label>
                      <input
                        type="text"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        placeholder="John"
                        className="px-4 py-3 mt-2 bg-white rounded-full border border-solid shadow-sm border-neutral-200 text-zinc-500"
                        required
                      />
                    </div>
                    <div className="flex flex-col">
                      <label className="text-black">Last name</label>
                      <input
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        placeholder="Smith"
                        className="px-4 py-3 mt-2 bg-white rounded-full border border-solid shadow-sm border-neutral-200 text-zinc-500"
                        required
                      />
                    </div>
                  </div>
                  <div className="flex flex-col mt-6">
                    <label className="text-black">Email address</label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      placeholder="email@janesfakedomain.net"
                      className="px-4 py-3 mt-2 bg-white rounded-full border border-solid shadow-sm border-neutral-200 text-zinc-500"
                      required
                    />
                  </div>
                  <div className="flex flex-col mt-6">
                    <label className="text-black">Your message</label>
                    <textarea
                      name="message"
                      value={formData.message}
                      onChange={handleChange}
                      placeholder="Enter your question or message"
                      className="px-4 pt-3 pb-8 mt-2 bg-white rounded-lg border border-solid shadow-sm border-neutral-200 text-zinc-500"
                      required
                    />
                  </div>
                  <motion.button
                    type="submit"
                    className="justify-center items-center px-6 py-2 mt-6 text-lg text-white bg-customBlue rounded-full shadow-sm hover:bg-blue-800"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    disabled={isLoading} // Disable button when loading
                  >
                    {isLoading ? (
                      <PulseLoader size={10} color={"#ffffff"} /> // Loading animation
                    ) : (
                      "Submit"
                    )}
                  </motion.button>
                  <AnimatePresence>
                    {submissionStatus && (
                      <motion.div
                        className="mt-4 text-center text-zinc-500"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5 }}
                      >
                        {submissionStatus}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
                <div className="flex justify-center items-center max-md:mt-10">
                  <img
                    loading="lazy"
                    srcSet={logo}
                    className="w-full max-w-xs"
                    alt="logo"
                  />
                </div>
              </div>
            </form>
          </div>
        </motion.div>
      </div>
      <Footer />
    </>
  );
}

export default Contact;
