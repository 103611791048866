import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import google from '../assets/google.svg';
import Footer from '../components/footer';
import { serverUrl } from '../config';

const Login = () => {
  const [isLogin, setIsLogin] = useState(true);

  const handleGoogleSignIn = () => {
    window.location.href = `${serverUrl}/google_login`; // Adjust this to your Flask server URL
  };

  const toggleForm = () => {
    setIsLogin(!isLogin);
  };

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex flex-grow justify-center items-center p-20 text-base leading-6 text-black bg-white max-md:px-5">
        <div className="flex flex-col items-center">
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="text-2xl font-semibold tracking-tight text-center"
          >
            Join Reviewmate.AI
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
            className="mt-1 text-center"
          >
            Use your Google account to continue
          </motion.div>
          <motion.div
            onClick={handleGoogleSignIn}
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.2 }}
            className="flex gap-5 px-4 py-2.5 mt-6 max-w-full font-medium whitespace-nowrap rounded-lg bg-zinc-100 w-[400px] cursor-pointer"
          >
            <img
              loading="lazy"
              src={google}
              className="shrink-0 w-5 aspect-square"
              alt="Google"
            />
            <div className="flex-auto text-center">Google</div>
          </motion.div>




          {/* Uncomment the code below to show the login / signup forms */}
          {/* <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
            className="mt-6 leading-6 text-center w-[400px]"
          >
            Or {isLogin ? 'log in' : 'sign up'} with your email
          </motion.div>
          <AnimatePresence>
            <motion.form
              key={isLogin ? 'login' : 'signup'}
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{ duration: 0.5 }}
              className="w-[400px] mt-4"
            >
              {!isLogin && (
                <>
                  <div className="flex flex-col mb-4">
                    <label className="mb-2 font-semibold">First Name</label>
                    <input
                      type="text"
                      className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                  </div>
                  <div className="flex flex-col mb-4">
                    <label className="mb-2 font-semibold">Last Name</label>
                    <input
                      type="text"
                      className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                  </div>
                </>
              )}
              <div className="flex flex-col mb-4">
                <label className="mb-2 font-semibold">Email</label>
                <input
                  type="email"
                  className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <div className="flex flex-col mb-4">
                <label className="mb-2 font-semibold">Password</label>
                <input
                  type="password"
                  className="px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
              <button
                type="submit"
                className="w-full px-4 py-2 font-semibold text-white bg-customBlue rounded-lg hover:bg-blue-600"
              >
                {isLogin ? 'Log In' : 'Sign Up'}
              </button>
            </motion.form>
          </AnimatePresence>
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.4 }}
            className="mt-4 text-center"
          >
            {isLogin ? (
              <>
                Don't have an account?{' '}
                <button
                  onClick={toggleForm}
                  className="font-semibold text-blue-500 focus:outline-none"
                >
                  Sign up
                </button>
              </>
            ) : (
              <>
                Already have an account?{' '}
                <button
                  onClick={toggleForm}
                  className="font-semibold text-blue-500 focus:outline-none"
                >
                  Log in
                </button>
              </>
            )}
          </motion.div> */}
          {/* Uncomment the code above to show the login/signup form */}
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.5 }}
            className="mt-6 leading-6 text-center w-[400px]"
          >
            By {isLogin ? 'logging in' : 'signing up'}, you agree to our{' '}
              <span className="text-blue-500"><a href='/terms'>Terms of Service </a></span>
              and{' '}
              <span className="text-blue-500"><a href='/privacy'> Privacy Policy</a></span>
          </motion.div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Login;
