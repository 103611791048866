import React, { useEffect, useState } from 'react';
import Footer from '../components/footer';
import { useNavigate, useLocation } from 'react-router-dom';
import {serverUrl} from '../config';

const pricePerReview = 1.99; // Price per review in USD
const taxRate = 0.13; // Tax rate (e.g., 13% for HST)
const minimumPurchase = 1; // The minimal purchase review at once

const Profile = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [user, setUser] = useState(null);

    const [quantity, setQuantity] = useState(minimumPurchase);
    const [showPurchaseOptions, setShowPurchaseOptions] = useState(false);


    useEffect(() => {
        // Retrieve token from URL parameters
        const query = new URLSearchParams(location.search);
        const token = query.get('token');
        if (token) {
            localStorage.setItem('token', token);
            fetchProfile(token);
        } else {
            // Retrieve token from local storage
            const storedToken = localStorage.getItem('token');
            if (storedToken) {
                fetchProfile(storedToken);
            } else {
                // If no token, redirect to login
                navigate('/login');
            }
        }

        const sShowPurchaseOpt = localStorage.getItem('gShowPurchaseOptions');
        if (sShowPurchaseOpt){
            setShowPurchaseOptions(true);
            localStorage.removeItem('gShowPurchaseOptions');
        }
    }, [location.search, navigate]);


    const handleQuantityChange = (e) => {
        const value = Math.max(minimumPurchase, Math.min(100, parseInt(e.target.value, 10)));
        setQuantity(value);
    };

    const handleDecrement = () => {
    setQuantity(prev => Math.max(minimumPurchase, prev - 1));
    };

    const handleIncrement = () => {
    setQuantity(prev => Math.min(100, prev + 1));
    };
    
    const handlePurchaseClick = async () => {
        try {
        const response = await fetch(`${serverUrl}/create_checkout_session`, {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            'Authorization': localStorage.getItem('token')
            },
            body: JSON.stringify({ quantity })
        });
        if (response.status === 403) {
            localStorage.removeItem('token');
            navigate('/login');
        }
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
    
        const data = await response.json();
        // Handle the response data (e.g., redirect to checkout page)
        console.log(data);
        window.location.replace(data.url);
        } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        }
    };
    
      const total = (quantity * pricePerReview * (1 + taxRate)).toFixed(2);

    const fetchProfile = async (token) => {
        try {
            const response = await fetch(`${serverUrl}/profile`, {
                headers: {
                    'Authorization': token
                }
            });
            if (response.status === 403) {
                localStorage.removeItem('token');
                navigate('/login');
            }
            const data = await response.json();
            setUser(data);
        } catch (error) {
            console.error('Error fetching profile data:', error);
            navigate('/');
        }
    };

    const handleSignOut = () => {
        // Clear local storage and navigate to login page
        localStorage.removeItem('token');
        localStorage.removeItem('reviews');
        setUser(null);
        navigate('/');
    
        // Refresh the page
        window.location.reload();
    };

    if (!user) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <div className="text-2xl font-semibold">Loading...</div>
            </div>
        );
    }

    return (
        <>
    <div className="flex flex-col min-h-screen">
        <div className="flex justify-center items-center px-16 py-20 text-base leading-6 bg-white max-md:px-5 flex-grow">
            <div className="flex flex-col mt-10 max-w-full w-[615px] max-md:mt-10">
                <div className="text-3xl font-semibold tracking-tight leading-8 text-black max-md:max-w-full">
                    Edit profile
                </div>
                <div className="flex gap-5 justify-between self-start mt-11 max-md:mt-10">
                    <img
                        loading="lazy"
                        src={user.picture}
                        className="shrink-0 w-16 aspect-square"
                        alt='Profile'
                    />
                    <div className="flex flex-col my-auto">
                        <div className="font-medium text-black text-ellipsis">
                            {user.name}
                        </div>
                    </div>
                </div>
                <div className="mt-8 font-medium text-black text-ellipsis max-md:max-w-full">
                    Email
                </div>
                <div className="justify-center px-4 py-2 mt-2 text-black whitespace-nowrap bg-white rounded-lg border border-solid border-neutral-200 text-ellipsis max-md:max-w-full">
                    {user.email}
                </div>
                <div className="mt-8 font-medium text-black text-ellipsis max-md:max-w-full">
                    Available Credits
                </div>
                <div className="justify-center px-4 py-2 mt-2 text-black bg-white rounded-lg border border-solid border-neutral-200 text-ellipsis max-md:max-w-full">
                    {user.credits}
                </div>
                {!showPurchaseOptions && (
                    <button className="justify-center self-start px-4 py-2 mt-8 font-medium text-white bg-customBlue rounded-lg" onClick={() => setShowPurchaseOptions(true)}>
                        {/* <a href='/purchase'>Add More Credits</a> */}
                        Add More Credits
                    </button>  
                )}   
                {showPurchaseOptions && (
                    <div className="flex flex-col items-center self-stretch text-lg leading-7 text-gray-700 max-md:mt-10 max-md:max-w-full my-6 border border-gray-300 border-2 rounded-xl px-4 py-2">
                    <div className="font-bold max-w-[85%] leading-normal text-muted-foreground sm:text-lg sm:leading-7">Select your quantity</div>
                    {/* <div className="max-w-[85%] leading-normal text-muted-foreground sm:text-lg sm:leading-7">The minimum purchase is {minimumPurchase} credits. Please select a quantity of 5 or more credits to proceed with your purchase.</div> */}
                    <div className="flex items-center justify-between mb-4">
                        <button onClick={handleDecrement} className="px-4 py-2 text-white bg-blue-700 rounded-lg">-</button>
                        <input
                        type="number"
                        value={quantity}
                        onChange={handleQuantityChange}
                        className="w-20 text-center border border-gray-300 rounded-lg"
                        min="1"
                        max="100"
                        />
                        <button onClick={handleIncrement} className="px-4 py-2 text-white bg-blue-700 rounded-lg">+</button>
                    </div>
                    <div className="text-lg text-gray-700">
                        <p>Total (including {taxRate * 100}% tax): ${total}</p>
                    </div>
                    <button onClick={handlePurchaseClick} className="mt-4 px-4 py-2 text-white bg-green-600 rounded-lg">
                        Purchase
                    </button>
                    <button onClick={() => setShowPurchaseOptions(false)} className="mt-4 px-4 py-2 text-white bg-red-600 rounded-lg">
                        Cancel
                    </button>
                    </div>
                )}
                <button className="justify-center self-start px-4 py-2 mt-8 font-medium text-white bg-red-600 rounded-lg" onClick={handleSignOut}>
                    Sign Out
                </button>
            </div>
        </div>
        <Footer />
    </div>
</>

    );
};

export default Profile;
