import React from 'react';
const Footer = () => {
    return (
        <footer class="border-t border-customBlue">
        <div className="flex flex-col self-stretch px-8 pb-4 w-full bg-white max-md:px-5 max-md:max-w-full">
            {/* <div className="shrink-0 h-px border border-solid bg-neutral-200 border-neutral-200 max-md:max-w-full" /> */}
            <div className="flex gap-5 mt-3 w-full max-md:flex-wrap max-md:mt-10 max-md:max-w-full">
            <div className="flex-auto self-start leading-9 text-black text-m">
                ReviewMate.AI
            </div>
            {/* <div className="flex flex-1 gap-2">
                <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/e1cbc6d58a74886c4d18e6f63acf6123066f74c5a476279d3a0ea678601ba09e?apiKey=4e75cefd43594fd9ae60388022aa78ae&"
                className="object-contain shrink-0 ml-auto w-full aspect-square max-w-[36px]"
                alt=''
                />
                <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/0828515f09127d365461d17e1036e88788811bd5c0258116139d171a870fbe56?apiKey=4e75cefd43594fd9ae60388022aa78ae&"
                className="shrink-0 w-10 aspect-square"
                alt=''

                />
                <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/b1bac3668b19309ab1c82835d154f9962b24bad9c262723d53374e3173c3e762?apiKey=4e75cefd43594fd9ae60388022aa78ae&"
                className="shrink-0 w-10 aspect-square"
                alt=''
                />
                <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/c9c8d64becb556072cf4288b2dadd32d18a3ce8e19d5cc15f298f66d1125313b?apiKey=4e75cefd43594fd9ae60388022aa78ae&"
                className="shrink-0 w-10 aspect-square"
                alt=''
                />
            </div> */}
            <div className="flex flex-1 gap-2 justify-end text-m leading-9 text-black">
                <a href='/privacy'> Privacy Policy</a>
                <a href='/terms'> Terms of Service </a>
            </div>
            </div>
        </div>
        </footer>
    );
};

export default Footer;