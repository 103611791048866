import React, { useState } from 'react';
import { motion } from 'framer-motion';
import Footer from '../components/footer';
import logo from '../assets/logo.png';
import api from '../assets/payment/api.svg';
import ai from '../assets/payment/ai.svg';
import cloud from '../assets/payment/cloud.svg';
import loading from '../assets/payment/loading.svg';
import { serverUrl } from '../config';
import { useNavigate } from 'react-router-dom';

const pricePerReview = 1.99; // Price per review in USD
const taxRate = 0.13; // Tax rate (e.g., 13% for HST)
// const minimumPurchase = 5; // The minimal purchase review at once

const faqs = [
  {
    question: "Why does it cost money?",
    answer: "Our platform uses advanced AI technology and secure infrastructure to provide top-notch service. Your credit purchases help us maintain and improve these services."
  },
  // Add more FAQs here if needed
];

const Pricing = () => {
  const [openFAQ, setOpenFAQ] = useState(null);
  // const [quantity, setQuantity] = useState(minimumPurchase);
  // const [showPurchaseOptions, setShowPurchaseOptions] = useState(false);
  const navigate = useNavigate();
  // const [isShowPurchaseDisabled, setIsShowPurchaseDisabled] = useState(false);


  const handlePurchaseOptions = async (state) =>{
    
    // const storedToken = localStorage.getItem('token');
    // if (storedToken) {
    //   try {
    //     const response = await fetch(`${serverUrl}/profile`, {
    //         headers: {
    //             'Authorization': storedToken
    //         }
    //     });
    //     if (response.status === 403) {
    //         localStorage.removeItem('token');
    //         navigate('/login');
    //     }
    //     navigate('/profile'); 
    //     const data = await response.json();
    //   } catch (error) {
    //       console.error('Error fetching profile data:', error);
    //       navigate('/');
    //   }
    // } else {
    //     // If no token, redirect to login
    //     navigate('/login');
    // }
    localStorage.setItem('gShowPurchaseOptions',true);

    navigate('/profile');  

    // setShowPurchaseOptions(state);
  };


  const toggleFAQ = (index) => {
    setOpenFAQ(openFAQ === index ? null : index);
  };

  // const handleQuantityChange = (e) => {
  //   const value = Math.max(minimumPurchase, Math.min(100, parseInt(e.target.value, 10)));
  //   setQuantity(value);
  // };

  // const handleDecrement = () => {
  //   setQuantity(prev => Math.max(minimumPurchase, prev - 1));
  // };

  // const handleIncrement = () => {
  //   setQuantity(prev => Math.min(100, prev + 1));
  // };

  // const handlePurchaseClick = async () => {
  //   try {
  //     const response = await fetch(`${serverUrl}/create_checkout_session`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         'Authorization': localStorage.getItem('token')
  //       },
  //       body: JSON.stringify({ quantity })
  //     });
  //     if (response.status === 403) {
  //       localStorage.removeItem('token');
  //       navigate('/login');
  //     }
  //     if (!response.ok) {
  //       throw new Error('Network response was not ok');
  //     }

  //     const data = await response.json();
  //     // Handle the response data (e.g., redirect to checkout page)
  //     console.log(data);
  //     window.location.replace(data.url);
  //   } catch (error) {
  //     console.error('There was a problem with the fetch operation:', error);
  //   }
  // };

  // const total = (quantity * pricePerReview * (1 + taxRate)).toFixed(2);

  return (
    <>
      <div className="flex flex-col items-center pb-20 bg-white">
        <div className="flex justify-center items-center self-stretch px-16 py-20 w-full text-center bg-blue-700 text-slate-50 max-md:px-5 max-md:max-w-full">
          <div className="flex flex-col mt-5 mb-20 max-md:mb-10 max-md:max-w-full">
            <motion.div
              className="text-6xl py-8 font-extrabold tracking-tight leading-[62.4px] max-md:max-w-full max-md:text-4xl"
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              Pricing Model
            </motion.div>
            <motion.div
              className="self-center mt-7 text-2xl font-small leading-8 max-md:max-w-full"
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              One simple plan to get you started
            </motion.div>
          </div>
        </div>
        {/* component start */}
        <motion.div
          className="z-10 py-px pr-12 mt-[-50px] max-w-full bg-gray-50 border-customLime border-2 rounded-xl shadow-xl w-[994px] max-md:pr-5 flex justify-center items-center"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className="flex gap-5 max-md:flex-col items-center w-full">
            <div className="flex flex-col w-[42%] max-md:w-full">
              <div
                className="m-1 flex flex-col grow items-center px-12 py-11 w-full font-extrabold text-gray-900 bg-blue-700 rounded-xl max-md:px-5 max-md:mt-10"
                
              >
                <div className="text-2xl leading-8 text-center">
                  <span className="text-white">Basic</span>
                </div>
                <div className="mt-7 text-6xl tracking-tight leading-[60px] max-md:text-4xl">
                  <span className="text-white">${pricePerReview}</span>
                </div>
                <div className="mt-5 text-lg font-medium leading-7 text-center">
                  <span className="text-white">per review</span>
                </div>
                <button
                  className="self-stretch px-16 py-5 mt-7 text-base font-bold leading-6 text-center bg-lime-500 rounded-lg text-white max-md:px-5"
                  onClick={() => handlePurchaseOptions(true)}
                >
                  Buy Credits
                </button>
              </div>
            </div>
            <div className="flex flex-col w-[58%] max-md:w-full">
              {/* {!showPurchaseOptions && ( */}
                <div className="flex flex-col items-start self-stretch text-lg leading-7 text-gray-700 max-md:mt-10 max-md:max-w-full my-6">
                  <div className="self-stretch leading-7 max-md:max-w-full">
                  Each review costs ${pricePerReview} USD, plus a {taxRate * 100}% HST tax. You can start with just one review and buy as many credits as you need.
                    <br />
                    Adjust the quantity to suit your needs and enjoy the flexibility of purchasing multiple reviews at once.
                  </div>
                  <div className="flex flex-auto gap-5 justify-center items-center self-stretch py-10">
                    <img
                      loading="lazy"
                      src={loading}
                      className="shrink-0 my-auto w-10 aspect-square"
                      alt=""
                    />
                    <div className="text-center">
                      No monthly subscription
                      <br />
                      Pay for what you need
                    </div>
                  </div>
                </div>
              {/* )} */}
              {/* {showPurchaseOptions && (
                <div className="flex flex-col items-center self-stretch text-lg leading-7 text-gray-700 max-md:mt-10 max-md:max-w-full my-6">
                  <div className="flex items-center justify-between mb-4">
                    <button onClick={handleDecrement} className="px-4 py-2 text-white bg-blue-700 rounded-lg">-</button>
                    <input
                      type="number"
                      value={quantity}
                      onChange={handleQuantityChange}
                      className="w-20 text-center border border-gray-300 rounded-lg"
                      min="1"
                      max="100"
                    />
                    <button onClick={handleIncrement} className="px-4 py-2 text-white bg-blue-700 rounded-lg">+</button>
                  </div>
                  <div className="text-lg text-gray-700">
                    <p>Total (including {taxRate * 100}% tax): ${total}</p>
                  </div>
                  <button onClick={handlePurchaseClick} className="mt-4 px-4 py-2 text-white bg-green-600 rounded-lg">
                    Purchase
                  </button>
                </div>
              )} */}
            </div>
          </div>
        </motion.div>
        {/* component end */}
        {/* What do we charge for? */}
        <motion.section
          id="features"
          className="container mx-auto px-4 space-y-6 bg-white py-8 md:py-12 lg:py-20"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
        >
          <div className="mx-auto flex max-w-[58rem] flex-col items-center space-y-4 text-center">
            <h2 className="font-bold text-3xl leading-[1.1] sm:text-3xl md:text-6xl">
            Why does this service cost money
            </h2>
            <p className="max-w-[85%] leading-normal text-muted-foreground sm:text-lg sm:leading-7">
            Providing this service involves operational costs that we need to cover. These include maintaining our servers, ensuring data security, and continuously improving our platform to offer the best user experience.
            </p>
          </div>
          <div className="mx-auto grid justify-center gap-4 sm:grid-cols-2 md:max-w-[64rem] md:grid-cols-3">
            <motion.div
              className="relative overflow-hidden rounded-lg border bg-white select-none hover:shadow hover:shadow-teal-200 p-2"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <div className="flex h-[180px] flex-col justify-between rounded-md p-6">
                <img src={api} alt="SVG" className="h-12 w-12 fill-current" />
                <div className="space-y-2">
                  <h3 className="font-bold">API Usage</h3>
                  <p className="text-sm text-muted-foreground">
                  API routes are called every time a review request is processed. To ensure seamless and reliable access, we need to keep them up-to-date and running efficiently
                  </p>
                </div>
              </div>
            </motion.div>
            <motion.div
              className="relative overflow-hidden rounded-lg border bg-white select-none hover:shadow hover:shadow-teal-200 p-2"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <div className="flex h-[180px] flex-col justify-between rounded-md p-6">
                <img src={ai} alt="SVG" className="h-12 w-12 fill-current" />
                <div className="space-y-2">
                  <h3 className="font-bold">AI Model</h3>
                  <p className="text-sm">
                  We use an advanced AI model to review your paper, ensuring accurate and instant feedback. This technology requires resources to maintain.
                  </p>
                </div>
              </div>
            </motion.div>
            <motion.div
              className="relative overflow-hidden rounded-lg border bg-white select-none hover:shadow hover:shadow-teal-200 p-2"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <div className="flex h-[180px] flex-col justify-between rounded-md p-6">
                <img src={cloud} alt="SVG" className="h-12 w-12 fill-current" />
                <div className="space-y-2">
                  <h3 className="font-bold">Cloud</h3>
                  <p className="text-sm text-muted-foreground">
                  To ensure rapid responses, we utilize top-tier cloud services. This commitment to speed and reliability requires investment in the best technology available.
                  </p>
                </div>
              </div>
            </motion.div>
          </div>
        </motion.section>
      </div>
      <Footer />
    </>
  );
};

export default Pricing;
