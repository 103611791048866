import React from 'react';

const ContactUnsuccessful = () => {
    return (
        <div class="bg-white h-screen flex justify-center items-center">
            <div class="bg-white p-6  md:mx-auto">
                <svg viewBox="0 0 24 24" class="text-red-600 w-16 h-16 mx-auto my-6">
                    <path fill="currentColor" d="M18.3 5.71L12 12.01 5.71 5.71A1 1 0 1 0 4.29 7.13L10.59 13.42 4.29 19.71A1 1 0 1 0 5.71 21.13L12 14.83 18.29 21.13A1 1 0 1 0 19.71 19.71L13.41 13.42 19.71 7.13A1 1 0 1 0 18.3 5.71z"/>
                </svg>
                <div class="text-center">
                    <h3 class="md:text-2xl text-base text-gray-900 font-semibold text-center">Error Occurred</h3>
                    <p class="text-gray-600 my-2">There was error in submitting your response.</p>
                    {/* <p class="text-gray-600 my-2">If issue persist please contact us.</p> */}
                    <p> Have a great day!  </p>
                    <div class="py-10 text-center">
                        <a href="/" class="px-12 bg-indigo-600 hover:bg-indigo-500 text-white font-semibold py-3">
                            Home
                    </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUnsuccessful;