import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import Header from '../components/header';
import Footer from '../components/footer';

const faqs = [
  {
    question: "How does ReviewMate.AI review a paper?",
    answer: `ReviewMate.AI uses OpenAI's GPT models to perform the review. The system combines the context of the paper alongside a set of predetermined review questions to query the GPT model. The system has been primarily tested with English-language papers, but it is also capable of handling documents in other languages.`
  },
  {
    question: "What considerations should be taken into account when evaluating the credibility of reviews?",
    answer: `This service is not meant to replace human reviewers. When using the features of Large Language Models (LLMs), several factors should be considered while assessing the review:\n
    • The reviews tend to be more positive with constructive feedback. It's advisable to focus on the information provided rather than solely relying on it for judgment.\n
    • Occurrences of "don't know" responses. These may appear when the system cannot find relevant context or fails to understand it properly, leading to unanswered sections. Common problem areas include AFFILIATION and PUBLISHER. Also, short papers, overly broad content are more likely to yield limited useful information in certain review sections. If you encounter this issue, consider regenerating the review to check for consistency. We appreciate any feedback on this matter.\n
    • Inconsistencies in review generation for some papers across different attempts. If you suspect the review quality is subpar, try regenerating it.\n
    Remember, while LLMs are powerful tools, their output should be critically evaluated and not taken at face value. Human oversight is critical.`
  },
  // {
  //   question: "What kinds of questions can ReviewMate.AI answer in the Q&A chat?",
  //   answer: `In the Q&A chat, ReviewMate.AI employs vector embedding to identify highly relevant context from the uploaded paper after receiving a user message. The system then queries the GPT model with this user message, incorporating these high-relevance context snippets to generate answers. This Q&A feature is specifically tailored for users who wish to delve deeper into aspects of the paper; however, it is not included within the standard reviews. Here are a few example questions you might ask:\n
  //   What is the novelty of the paper?\n
  //   What are the limitations of the methodology?\n
  //   What evaluation metrics are used in the research?\n
  //   We encourage you to experiment with any other questions you have in mind. Please note that the current Q&A system offers a basic query-and-response function, meaning each question is treated independently without the context of previous queries. We might consider enhancing this feature based on user feedback.`
  // },
  {
    question: "What are the benefits of 'Log in with Google'?",
    answer: `The "Log in with Google" feature allows you to register on ReviewMate.AI using your Google account. To initiate your review process you must "Log in with Google".`
  },
  {
    question: "What are credits?",
    answer: `Once you log in, you have the option to directly purchase credits for your data tokens through our platform. These data tokens are used to measure the workloads associated with reviewing your uploaded paper, as they are required to prompt the OpenAI models. Should you exhaust your credits, you can select "Upgrade Your Account" to purchase additional ones. The typical credit expenditure for reviewing one paper is 1 credit. Your current credit balance is displayed under your profile page.`
  },
  // {
  //   question: "What are the benefits of using the API keys?",
  //   answer: `Using your own API keys eliminates the need to log in, and as long as your API key remains valid, you are free to upload as many papers as desired. However, this requires you to first register on the OpenAI site and acquire a valid key that grants access to advanced models, including GPT-4-1106. If you do not have your own OpenAI API key, we recommend logging in with your Google account.`
  // },
  {
    question: "Will ReviewMate.AI collect my data?",
    answer: `The only data collected is:\n
    • your feedback on the review\n
    • your email address information and account credits\n
    Please note that when you are using OpenAI key, your account information will not be collected. Additionally, the site is using model endpoints from OpenAI, which means your data will be handled by their services. According to OpenAI's policy, your uploaded data will not be used in model training and may be retained by OpenAI up to 30 days only for abuse identification. You might want to read the policy of OpenAI on https://openai.com/enterprise-privacy.`
  },
  {
    question: "What data is stored on ReviewMate.AI?",
    answer: `The only data being stored on the database is:\n
    • your feedback on the review\n
    • your basic email information and account credits\n
    We do not store any of your paper data in our database. Your session history only shows the papers you opened in that session. The papers are only temporarily stored in the system's RAM and will be removed after you close, refresh, or log out of your session page.`
  },
  {
    question: "How can I provide feedback?",
    answer: `To provie feedback please use our Contact Us page. `
  },
];

const Accordion = () => {
  const [openFAQ, setOpenFAQ] = useState(null);

  const toggleFAQ = (index) => {
    if (openFAQ === index) {
      setOpenFAQ(null);
    } else {
      setOpenFAQ(index);
    }
  };

  return (
    <div>
      <div className="flex flex-col items-center pb-2 bg-white">
        <div className="flex justify-center items-center self-stretch px-16 py-20 w-full text-center bg-blue-700 text-slate-50 max-md:px-5 max-md:max-w-full">
          <div className="flex flex-col mt-5 mb-20 max-md:mb-10 max-md:max-w-full">
            <motion.div
              className="text-6xl py-8 font-extrabold tracking-tight leading-[62.4px] max-md:max-w-full max-md:text-4xl"
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              Frequently Asked Questions
            </motion.div>
            <motion.div
              className="self-center mt-7 text-2xl font-small leading-8 max-md:max-w-full"
              initial={{ opacity: 0, y: -50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: 0.2 }}
            >
              Please find answers to general questions here or contact us.
            </motion.div>
          </div>
        </div>
        <motion.div
          className="w-full max-w-[1279px] mt-[-50px] bg-white px-6 py-8 my-10 rounded-xl shadow-xl border-2 border-customLime"
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          {faqs.map((faq, index) => (
            <div key={index} className="mb-4">
              <div
                className="flex justify-between items-center p-4 bg-blue-100 rounded-lg cursor-pointer"
                onClick={() => toggleFAQ(index)}
              >
                <div className="text-2xl text-black">{faq.question}</div>
                <div className="text-4xl text-zinc-500">{openFAQ === index ? '-' : '+'}</div>
              </div>
              <AnimatePresence>
                {openFAQ === index && (
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: 'auto', opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    className="overflow-hidden"
                  >
                    <div className="p-4 text-xl text-gray-700 bg-blue-50 rounded-b-lg whitespace-pre-line">
                      {faq.answer}
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          ))}
        </motion.div>
      </div>
      <Footer />
    </div>
  );
};

export default Accordion;
